import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'usehooks-ts'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../types'
import tokens from '../../../styles/tokens.json'
import InvoicesFilterCategoryContainer from './InvoicesFilterCategoryContainer'
import {
  getPaymentSelectedFilters,
  setFilterValue,
  PaymentSelectedFiltersConstants,
} from '../../../store/invoices/paymentSelectedFiltersSlice'

const styles: Styles = {
  error: {
    color: tokens.colors.rsmRed.secondary,
    marginLeft: '1.063rem',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
  },
  inputLabel: {
    backgroundColor: tokens.colors.white,
    paddingX: '0.0625rem',
  },
  textBox: {
    padding: '0rem 2.75rem 0rem 0.5625rem',
  },
}

interface PaymentsAmountRangeFilterProps {
  filterCategory: string
  clearCategoryFilter: (category: string) => void
  changeFilterAndSearch: (filter: string) => void
}

const PaymentsAmountRangeFilter = ({
  filterCategory,
  clearCategoryFilter,
  changeFilterAndSearch,
}: PaymentsAmountRangeFilterProps) => {
  const { t } = useTranslation()
  const selectedFilters = useSelector(getPaymentSelectedFilters)
  const [min, setMin] = useState<string | null>(null)
  const [max, setMax] = useState<string | null>(null)
  const [minDebounceValue, setMinDebounceValue] = useState<string | null>(null)
  const [maxDebounceValue, setMaxDebounceValue] = useState<string | null>(null)
  const [minError, setMinError] = useState<string | null>(null)
  const [maxError, setMaxError] = useState<string | null>(null)
  const minDebounce = useDebounce(minDebounceValue, 500)
  const maxDebounce = useDebounce(maxDebounceValue, 500)

  useEffect(() => {
    if (!maxError) {
      changeFilterAndSearch(
        setFilterValue(
          PaymentSelectedFiltersConstants.paymentAmountTo,
          maxDebounceValue,
        ),
      )
    }
    if (!minError) {
      changeFilterAndSearch(
        setFilterValue(
          PaymentSelectedFiltersConstants.paymentAmountFrom,
          minDebounceValue,
        ),
      )
    }
  }, [minDebounce, maxDebounce])

  useEffect(() => {
    if (!selectedFilters.paymentAmountFrom) {
      setMin(null)
      setMinDebounceValue(null)
    }
  }, [selectedFilters.paymentAmountFrom])

  useEffect(() => {
    if (!selectedFilters.paymentAmountTo) {
      setMax(null)
      setMaxDebounceValue(null)
    }
  }, [selectedFilters.paymentAmountTo])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    filter: string,
  ) => {
    const value = e.target.value || null
    if (filter === PaymentSelectedFiltersConstants.paymentAmountFrom) {
      setMin(value)
      setMinDebounceValue(value)
    } else {
      setMax(value)
      setMaxDebounceValue(value)
    }
  }

  const validateNumber = (
    value: number,
    setError: (errorValue: string | null) => void,
  ) => {
    if (Number.isNaN(value)) {
      setError('InvalidNumberError')
    } else if (value < 0) {
      setError('NegativeNumberError')
    } else if (!Number.isInteger(value)) {
      setError('WholeNumberError')
    } else {
      setError(null)
    }
  }

  useEffect(() => {
    const [minNum, maxNum] = [Number(min), Number(max)]
    validateNumber(minNum, setMinError)
    validateNumber(maxNum, setMaxError)

    // if values in textbox are invalid, attempt comparison against selectedFilters values
    const minComp = minError ? selectedFilters.paymentAmountFrom : minNum
    const maxComp = maxError ? selectedFilters.paymentAmountTo : maxNum

    if (!Number.isNaN(minNum) && minNum !== 0 && maxComp && minNum > maxComp) {
      setMinError('GreaterThanMaxError')
    }
    if (!Number.isNaN(maxNum) && maxNum !== 0 && minComp && maxNum < minComp) {
      setMaxError('LessThanMinError')
    }
  }, [min, max, setMinError, setMaxError])

  return (
    <InvoicesFilterCategoryContainer
      filterCategory={filterCategory}
      filterTitleKey="Invoicing.PaymentAmountRangeFilter.Title"
      clearCategoryFilter={clearCategoryFilter}>
      <Box sx={styles.textBox}>
        <TextField
          id="minFilter"
          label={t('Invoicing.PaymentAmountRangeFilter.Minimum')}
          data-testid={`Txt_Payment_Min${filterCategory}`}
          margin="dense"
          InputLabelProps={{ sx: styles.inputLabel }}
          error={!!minError}
          variant="outlined"
          aria-label={t('Invoicing.PaymentAmountRangeFilter.Minimum')}
          inputProps={{
            'aria-describedby': 'minFilterError',
          }}
          value={min === null ? '' : min}
          onChange={(e) => {
            handleChange(e, PaymentSelectedFiltersConstants.paymentAmountFrom)
          }}
        />
      </Box>
      {!!minError && (
        <Box id="minFilterError" sx={styles.error} component="span">
          {t(`Invoicing.PaymentAmountRangeFilter.${minError}`)}
        </Box>
      )}
      <Box sx={styles.textBox}>
        <TextField
          id="maxFilter"
          label={t('Invoicing.PaymentAmountRangeFilter.Maximum')}
          data-testid={`Txt_Payment_Max${filterCategory}`}
          margin="dense"
          InputLabelProps={{ sx: styles.inputLabel }}
          error={!!maxError}
          variant="outlined"
          aria-label={t('Invoicing.PaymentAmountRangeFilter.Maximum')}
          inputProps={{
            'aria-describedby': 'maxFilterError',
          }}
          value={max === null ? '' : max}
          onChange={(e) => {
            handleChange(e, PaymentSelectedFiltersConstants.paymentAmountTo)
          }}
        />
      </Box>
      {!!maxError && (
        <Box id="maxFilterError" sx={styles.error} component="span">
          {t(`Invoicing.PaymentAmountRangeFilter.${maxError}`)}
        </Box>
      )}
    </InvoicesFilterCategoryContainer>
  )
}

export default PaymentsAmountRangeFilter
