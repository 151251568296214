import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ClientViewModel } from '@rsmus/ecp-financeservice'
import {
  getInvoiceSelectedFilters,
  InvoiceSelectedFiltersConstants,
  InvoiceSelectedFiltersState,
  setFilterValue,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'
import FilterChips, { FilterChipData } from '../../FilterChips/FilterChips'
import { formatCurrencyNoDecimal } from '../../../rsmCoreComponents/utils/formatters'
import api from '../../../api'

const getChipsData = async (
  filters: InvoiceSelectedFiltersState,
): Promise<FilterChipData<string>[]> => {
  // Check if each filter has values and add it to the array.
  const chipsData = []

  if (filters.invoiceStatus?.length > 0) {
    const statuses = filters.invoiceStatus.map((i: string) => ({
      property: InvoiceSelectedFiltersConstants.invoiceStatus,
      value: i,
    }))
    chipsData.push(...statuses)
  }

  if (filters.invoiceDateRange.length > 0) {
    chipsData.push({
      property: InvoiceSelectedFiltersConstants.invoiceDates,
      value: filters.invoiceDateRange,
    })
  }

  if (filters.invoiceAmountFrom) {
    chipsData.push({
      property: InvoiceSelectedFiltersConstants.invoiceAmountFrom,
      value: formatCurrencyNoDecimal(
        filters.invoiceAmountFrom,
        filters.currency,
      ),
    })
  }

  if (filters.invoiceAmountTo) {
    chipsData.push({
      property: InvoiceSelectedFiltersConstants.invoiceAmountTo,
      value: formatCurrencyNoDecimal(filters.invoiceAmountTo, filters.currency),
    })
  }

  if (filters.clientId) {
    let clientInfo: ClientViewModel | undefined
    try {
      clientInfo = (await api.finance.client_GetClientDetails(filters.clientId))
        .data
    } catch (error) {
      // intentionally swallow errors and fallback to client id if name not available
    }
    if (clientInfo) {
      chipsData.push({
        property: InvoiceSelectedFiltersConstants.clientId,
        value: clientInfo.name,
      })
    } else {
      chipsData.push({
        property: InvoiceSelectedFiltersConstants.clientId,
        value: filters.clientId,
      })
    }
  }

  return chipsData as FilterChipData<string>[]
}

interface InvoicesChipsProps {
  changeFilterAndSearch: (filterValue: string) => void
  clearCategoryFilter: (category: string) => void
  clearAllFilters: () => void
}

const InvoicesChips = ({
  changeFilterAndSearch,
  clearCategoryFilter,
  clearAllFilters,
}: InvoicesChipsProps) => {
  const selectedFilters = useSelector(getInvoiceSelectedFilters)

  const [filterChipsData, setFilterChipsData] = useState<
    FilterChipData<string>[]
  >([])

  useEffect(() => {
    getChipsData(selectedFilters).then((chipsData) => {
      setFilterChipsData(chipsData)
    })
  }, [selectedFilters])

  const handleDeleteChip = useCallback(
    (chipData: FilterChipData<string>) => {
      if (chipData.property === InvoiceSelectedFiltersConstants.invoiceDates) {
        clearCategoryFilter(InvoiceSelectedFiltersConstants.invoiceDates)
        return
      }

      changeFilterAndSearch(
        setFilterValue(chipData.property as string, chipData.value),
      )
    },
    [clearCategoryFilter, changeFilterAndSearch, setFilterValue],
  )

  return (
    <FilterChips<string>
      filtersSelected={filterChipsData}
      filterChipTranslationKeyPrefix="Invoicing.FilterGroups"
      onChipDeleted={handleDeleteChip}
      onClearAll={clearAllFilters}
      clearAllFocusQuerySelector="#invoices-filter-chips-clear-all-focus-target input"
    />
  )
}

export default InvoicesChips
